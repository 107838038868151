import Vue from 'vue'

const that = Vue.prototype

that.state = Vue.observable({
  token: localStorage.token || false,
  loading: true,
  gotten: false,
  userInfo: {
    firstName: 'مهمان',
    lastName: '',
    role: '',
    rolePersian: '',
    privileges: [],
  },
  requestCount: 0,
  menuItem: [],
  viewedMenus: localStorage.route ? JSON.parse(localStorage.route) : [],
})
