import Vue from 'vue'

// axios
import axios from 'axios'
import router from "@/router";

const that = Vue.prototype

if (that.state.token) {
  axios.defaults.headers.common.Authorization = that.state.token
}

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/'
axios.defaults.baseURL = 'http://exchangeaz-api.9.vistadev.ir/api'


axios.interceptors.response.use(
    res => {
      that.state.loading = false

      return res
    },
    async err => {
      that.state.loading = false

      if (err.response.status) {

        if (err.response.status === 403) {
          await router.push('/login')
          that.$swal({
            icon: 'error',
            title: 'خطایی شبکه!',
            text: 'متاسفانه در این لحظه در سمت سرور مشکلی رخ داده است!',
            confirmButtonText: 'متوجه شدم',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (err.response.status === 500) {
          router.back()
          that.$swal({
            icon: 'error',
            title: 'خطایی شبکه!',
            text: 'متاسفانه در این لحظه در سمت سرور مشکلی رخ داده است!',
            confirmButtonText: 'متوجه شدم',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      }


      // console.error('result : ', err.response.status)

      /*console.warn(
          //     'config : ', err.config,
          //     'code : ', err.code,
          'request : ', err.request,
          //     'response : ', err.response,
          //     'isAxios : ', err.isAxiosError,
          //     'json : ', err.toJSON()
      )*/

      // if (res.config.responseType === 'blob') {
      //   const vm = that
      //   let reader = new FileReader();
      //   reader.onload = function (e) {
      //     let test = JSON.parse(e.target.result);
      //     vm.$error('', vm.$errorHandeling[test.message])
      //   };
      //   reader.readAsText(err.response.data);
      //   return Promise.reject(err);
      // }

      // if (err.response) if (res.data.message) if (res.data.message.includes('JWT') || res.data.message.includes('Invalid Token')) {
      //   that.$logout()
      //   return Promise.reject(err);
      // }

      // if (err.response ? ['UNAUTHORIZED'].includes(res.data.status) : false) {
      //   return Promise.reject(err);
      // }

      // if (err.response ? ['Validation Error'].includes(res.data.message) : false) {
      //   return Promise.reject(err);
      // }

      // if (res.data.message === 'Market Is Inactive') {
      //   that.$error('بازار غیر فعال است')
      //   return Promise.reject(err);
      // }

      // const text = err.response ?
      //     that.$errorHandeling[err.response.data.message]
      //     || that.$errorHandeling[err.response.data.status]
      //     || res.data.errors
      //     || res.data.error
      //     : res.message

      // that.$swal({
      //   icon: 'error',
      //   title: err.request.status,
      //   text: text,
      //   confirmButtonText: 'متوجه شدم',
      //   customClass: {
      //     confirmButton: 'btn btn-success',
      //   },
      // })

      return Promise.reject(err);
    },)

that.$axios = axios

export default axios



// const axiosIns = axios.create({
//   // You can add your headers here
//   // ================================
//   baseURL: 'http://127.0.0.1:8000/api/',
//   // timeout: 1000,
//   // headers: {'X-Custom-Header': 'foobar'}
// })
//
// Vue.prototype.$http = axiosIns
//
// export default axiosIns

// const that = Vue.prototype
