import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

import VueSweetalert2 from 'vue-sweetalert2'
import router from './router'
import store from './store'
import App from './App.vue'
// If you don't need the styles, do not connect
// import 'sweetalert2/dist/sweetalert2.min.css'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/@core/scss/vue/libs/vue-select.scss'
import '@/libs/state'
import '@/libs/globalFunctions'
import '@/libs/globalConstants'
import '@/libs/axios'

Vue.directive('ripple', Ripple)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSweetalert2)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// impost Yekan font
require('@/assets/font/Font-Yekan.css')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
