import Vue from 'vue'

// import './georgianToJalali'
// import axios from 'axios'

const that = Vue.prototype

that.$sleep = ms => (new Promise(resolve => setTimeout(resolve, ms)))

that.$p2e = string => string.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))

that.$toLocal = number => {
  // purify number
  // eslint-disable-next-line no-param-reassign
  number = `${number}`
  if (number.includes('E')) {
    // eslint-disable-next-line no-param-reassign
    number = `${parseFloat(number)}`
  }
  // eslint-disable-next-line no-param-reassign
  number = that.$p2e(number).replace(/[^\d.]|\.\./ig, '').replace(/,/g, '')
  if (number.includes('.')) {
    // eslint-disable-next-line no-param-reassign
    number = `${number.split('.')[0]}.${number.split('.')[1] || ''}`
  }

  if (parseFloat(number)) {
    if (parseFloat(number) < 1.0e-8) {
      // zero numbers
      return 0
    }
    // separate number
    const int = parseFloat(number.split('.')[0]).toLocaleString()
    if (number.includes('.')) {
      return `${int}.${number.split('.')[1] || ''}`
    }
    return int
  }
  return number
}

that.$S2N = e => parseFloat(e.replace(/,/g, ''))

that.$marketType2relatedCoin = marketType => marketType.replace(/_TOMAN|_TETHER/, '')

that.$error = async (title, text, icon = 'error', button = 'متوجه شدم') => {
  await that.$swal({
    icon,
    title,
    text,
    confirmButtonText: button,
    customClass: {
      confirmButton: 'btn-success',
    },
  })
}

that.$count = (find, array) => {
  let count = 0
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) if (array[i].includes(find)) count++
  return count
}

that.$toSnakeCase = str => str
    && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toUpperCase())
      .join('_')

// eslint-disable-next-line no-nested-ternary
that.$numFormatter = num => (num < 1000 ? num.toLocaleString()
// eslint-disable-next-line no-nested-ternary
  : num < 1000000 ? `${(num / 1000).toFixed(1).toLocaleString()}k`
    : num < 1000000000 ? `${(num / 1000000).toFixed(1).toLocaleString()}m`
      : `${(num / 1000000000).toFixed(1).toLocaleString()}b`)

that.$getUserInfo = async () => {
  let info = await that.$axios('/admin-panel/admin-info')
  info = info.data

  // const roleDetails = await that.$axios(`/roles/${info.role.id}`)

  that.state.userInfo = {
    firstName: info.firstName,
    lastName: info.lastName,
    role: info.roles[0].englishName,
    rolePersian: info.roles[0].persianName,
    privileges: info.roles[0].permissions,
  }

  // if (that.state.userInfo.privileges.includes('LIST_WITHDRAW')) {
  //   const requestCount = await this.$axios('/wallets/withdrawal-requests?withdrawalRequestStatus=RAISED_BY_USER')
  //   that.state.requestCount = requestCount.data.totalElements
  // }

  // console.log(window.navigator.appCodeName)

  that.state.gotten = true
}

that.$makeBookmarkData = async e => {
  const data = []
  // eslint-disable-next-line no-shadow
  const loop = async (e, title = '', icon = '') => {
    await e.forEach(el => {
      if (!el.children) {
        if (!title) {
          data.push(
            {
              title: el.title,
              route: { name: el.route },
              icon: el.icon,
              isBookmarked: localStorage.bookmarks ? JSON.parse(localStorage.bookmarks).includes(el.title) : false,
            },
          )
        } else {
          data.push(
            {
              route: { name: el.route },
              title: `${title}/ ${el.title}`,
              icon,
              isBookmarked: localStorage.bookmarks ? JSON.parse(localStorage.bookmarks).includes(`${title}/ ${el.title}`) : false,
            },
          )
        }
      } else {
        loop(el.children, (title ? `${title}/ ` : '') + el.title, icon || el.icon, data)
      }
    })
  }
  await loop(e)
  return data
}

that.$filter = data => {
  for (let i = 0; i < data.length; i += 1) {
    let remove = true
    for (let role of data[i].role) {
      // console.log('loop', role)
      if (role === 'all') {
        remove = false
        continue
      }
      if (that.state.userInfo.privileges.find(e => e.name === role)) {
        remove = false
        break
      }
    }
    if (remove) {
      data.splice(i, 1)
      // eslint-disable-next-line no-plusplus
      i -= 1
    } else if (data[i].children) that.$filter(data[i].children)
  }
}

that.$havePermission = role => that.state.userInfo.privileges.find(e => e.name === role)

that.$logout = () => {
  localStorage.clear('token')
  that.state.token = ''
  that.state.gotten = false
  that.$axios.defaults.headers.common.Authorization = that.state.token
  try {
    that.$router.push('/login')
  } catch (e) {
    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }
}

that.$test = e => {
  console.log('event test : ', e)
}
