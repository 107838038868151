import Vue from 'vue'
import VueRouter from 'vue-router'
import data from '@/navigation/vertical/index'

Vue.use(VueRouter)

const that = Vue.prototype

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    //  Dashboard
    {
      path: '/',
      name: 'Dashboard',
      component: () => import('@/views/Dashboard/Home.vue'),
      meta: {
        pageTitle: 'داشبورد',
        breadcrumb: [
          {
            text: 'داشبورد',
            active: true,
          },
        ],
      },
    },
    //  Users
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/Users/Users.vue'),
      meta: {
        pageTitle: 'کاربران سایت',
        breadcrumb: [
          {
            text: 'لیست کاربران',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/:id',
      name: 'show-user',
      component: () => import('@/views/Users/users-view/UsersView.vue'),
      meta: {
        pageTitle: 'کاربران سایت',
        breadcrumb: [
          {
            text: 'لیست کاربران',
            active: false,
            to: '/users',
          },
          {
            text: 'مشاهده کاربر',
            active: true,
          },
        ],
      },
    },
    // panel
    {
      path: '/admin-accounts',
      name: 'admin-accounts',
      component: () => import('@/views/Admins/AdminAccounts.vue'),
      meta: {
        pageTitle: 'مدیران سایت',
        breadcrumb: [
          {
            text: 'مدیریت سامانه',
            active: true,
          },
          {
            text: 'لیست مدیران',
            active: true,
          },
        ],
      },
    },
    // dollar
    {
      path: '/dollar',
      name: 'dollar',
      component: () => import('@/views/DollarPrice.vue'),
      meta: {
        pageTitle: 'تاریخچه قیمت دلار',
        breadcrumb: [
          {
            text: 'مدیریت مالی',
            active: true,
          },
          {
            text: 'مدیریت دلار',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cryptocurrency',
      name: 'cryptocurrency',
      component: () => import('@/views/CryptoCurrency/CryptoCurrency.vue'),
      meta: {
        pageTitle: 'لیست رمز ارزها',
        breadcrumb: [
          {
            text: 'مدیریت رمز ارزها',
            active: true,
          },
          {
            text: 'لیست رمز ارز',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cryptocurrency/:id',
      name: 'edit-cryptocurrency',
      component: () => import('@/views/CryptoCurrency/CryptoEdit.vue'),
      meta: {
        pageTitle: 'ویرایش رمز ارز',
        breadcrumb: [
          {
            text: 'لیست رمز ارزها',
            active: false,
            to: '/cryptocurrency',
          },
          {
            text: 'ویرایش رمز ارز',
            active: true,
          },
        ],
      },
    },
    // Orders
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/Orders/Orders.vue'),
      meta: {
        pageTitle: 'لیست سفارشات',
        breadcrumb: [
          {
            text: 'سفارشات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/order-details/:id',
      name: 'order-details',
      component: () => import('@/views/Orders/OrderDetails.vue'),
      meta: {
        pageTitle: 'جزییات سفارش',
        breadcrumb: [
          {
            text: 'سفارشات',
            active: true,
          },
        ],
      },
    },
    //    wallet address
    {
      path: '/crypto-wallet-addresses',
      name: 'crypto-wallet-addresses',
      component: () => import('@/views/CryptoWalletAddress/CryptoWalletAddresses.vue'),
      meta: {
        pageTitle: 'آدرس کیف پول رمز ارزها',
        breadcrumb: [
          {
            text: 'لیست رمز ارزها',
            active: true,
          },
        ],
      },
    },
    //  Authentication
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        no_auth: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        no_auth: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.beforeEach(async (to, from, next) => {
  if (that.state.token && !that.state.gotten) {
    that.state.loading = true
    await that.$getUserInfo()
    const sourceData = JSON.parse(JSON.stringify(data))
    await that.$filter(sourceData)
    that.state.menuItem = sourceData
  }
  if (to.meta.no_auth && that.state.token) next('/')
  else if ((to.meta.no_auth && !that.state.token) || (!to.meta.no_auth && that.state.token)) next()
  else if (!to.meta.no_auth && !that.state.token) next('/login')
})
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
