import Vue from 'vue'

const that = Vue.prototype

that.$perivilageData = [
  {
    module: 'USERS',
    LIST: true,
    DETAILS: true,
    CREATE: false,
    UPDATE: true,
    DELETE: true,
  },
  {
    module: 'ADMINS',
    LIST: true,
    DETAILS: true,
    CREATE: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    module: 'ROLES',
    LIST: true,
    DETAILS: true,
    CREATE: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    module: 'DOLLARS',
    LIST: true,
    DETAILS: true,
    CREATE: true,
    UPDATE: false,
    DELETE: false,
  },
  {
    module: 'WALLET',
    LIST: true,
    DETAILS: true,
    CREATE: true,
    UPDATE: true,
    DELETE: false,
  },
  {
    module: 'WITHDRAW',
    LIST: true,
    DETAILS: true,
    CREATE: false,
    UPDATE: true,
    DELETE: true,
  },
  {
    module: 'HISTORIES',
    LIST: true,
    DETAILS: true,
    CREATE: false,
    UPDATE: false,
    DELETE: false,
  },
  {
    module: 'FAQ',
    LIST: true,
    DETAILS: false,
    CREATE: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    module: 'USER_MANUAL',
    LIST: true,
    DETAILS: false,
    CREATE: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    module: 'TRADES',
    LIST: true,
    DETAILS: true,
    CREATE: false,
    UPDATE: true,
    DELETE: false,
  },
  {
    module: 'ORDERS',
    LIST: true,
    DETAILS: false,
    CREATE: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    module: 'MARKET',
    LIST: true,
    DETAILS: true,
    CREATE: true,
    UPDATE: true,
    DELETE: true,
  },
]

that.$subPerivilage = [
  'UPDATE',
  'LIST',
  'DELETE',
  'CREATE',
  'DETAILS',
]

that.$coinLabel = {
  TOMAN: 'تومان',
  BITCOIN: 'بیتکوین',
  BITCOIN_CASH: 'بیتکوین کش',
  ETHEREUM: 'اتریوم',
  CLASSIC_ETHEREUM: 'اتریوم کلاسیک',
  LITE_COIN: 'لایت کوین',
  BINANCE_COIN: 'بایننس کوین',
  EOS: 'ایاس',
  STELLAR: 'استلار',
  RIPPLE: 'ریپل',
  TRON: 'ترون',
  DASH: 'دش',
  TETHER: 'تتر',
  DOGE_COIN: 'دوج کوین',
}

that.$coinUnit = {
  TOMAN: 'تومان',
  BITCOIN: 'BTC',
  BITCOIN_CASH: 'BCH',
  ETHEREUM: 'ETH',
  CLASSIC_ETHEREUM: 'ETC',
  LITE_COIN: 'LTC',
  BINANCE_COIN: 'BNB',
  EOS: 'EOS',
  STELLAR: 'XLM',
  RIPPLE: 'XRP',
  TRON: 'TRX',
  DASH: 'DASH',
  TETHER: 'تتر',
  DOGE_COIN: 'DOGE',
}

that.$errorHandeling = {
  BAD_REQUEST: 'اطلاعات ارسال شده اشتباه است',
  NOT_FOUND: 'اطلاعات درخواستی یافت نشد',
  FINNOTECH_NOT_COMPLETED: 'کاربر فرآیند فینوتک را تکمیل نکرده است',
  CONFLICT: 'اجازه ندارید',
  FORBIDDEN: 'دسترسی لازم را ندارید',
  CARD_NUMBER_DOESNT_MATCH_SHEBA: 'عدم تطابق شماره کارت و شماره شبا',
  EXCEL_NOT_GENERATED: 'اطلاعاتی جهت خروجی اکسل وجود ندارد',
}
