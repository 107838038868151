export default [
  {
    title: 'داشبورد',
    route: 'Dashboard',
    icon: 'HomeIcon',
    role: ['all'],
  },
  {
    title: 'کاربران سایت',
    route: 'users',
    icon: 'UsersIcon',
    role: ['users-list'],
  },
  //  Admin and Roles and Permissions
  {
    title: 'مدیریت سامانه',
    icon: 'MonitorIcon',
    role: ['all'],
    // role: ['LIST_ADMINS', 'LIST_ROLES'],
    children: [
      {
        title: 'مدیران',
        route: 'admin-accounts',
        role: ['admin-list'],
        // role: ['LIST_ADMINS']
      },
      {
        title: 'نقش ها و دسترسی ها',
        route: 'admin-roles',
        role: ['all'],
        // role: ['LIST_ROLES']
      },
      {
        title: 'افزودن نقش',
        route: 'add-role',
        role: ['all'],
        // role: ['CREATE_ROLES']
      },
      {
        title: 'مشاهده نقش',
        route: 'show-role',
        // role: ['UPDATE_ROLES'],
        disabled: true,
        role: ['all'],
      },
    ],
  },
  //  Currency
  {
    title: 'مدیریت دلار',
    route: 'dollar',
    icon: 'DollarSignIcon',
    role: ['all'],
    // role: ['LIST_DOLLARS'],
  },
  //  CryptoCurrency
  {
    title: 'مدیریت رمز ارز ها',
    route: 'cryptocurrency',
    tagVariant: 'light-info',
    // tag: 'new',
    icon: 'CpuIcon',
    role: ['all'],
    // role: ['all'],
  },
  //    Orders
  {
    title: 'سفارشات',
    route: 'orders',
    // tagVariant: 'light-info',
    icon: 'ShoppingCartIcon',
    role: ['all'],
  },
  //    wallet address
  {
    title: ' مدیریت آدرس رمز ارزها',
    route: 'crypto-wallet-addresses',
    icon: 'GlobeIcon',
    role: ['all'],
  },
  {
    title: 'بلاگ',
    route: 'support',
    icon: 'TrelloIcon',
    role: ['all'],
  },
  {
    title: 'پشتیبانی',
    route: 'support',
    icon: 'MessageSquareIcon',
    role: ['all'],
  },
  {
    title: 'تنظیمات سایت',
    route: 'setting',
    icon: 'SettingsIcon',
    role: ['all'],
  },
]
